/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "루비 2.6 릴리즈 소식을 전합니다. 이어서 블로그로 돈 못 버는 세 블로거가 컨텐츠로 돈 버는 이야기를 했습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ruby-lang.org/en/news/2018/12/25/ruby-2-6-0-released/"
  }, "Ruby 2.6.0 Released | Ruby")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/ruby/ruby/blob/ruby_2_6/include/ruby/version.h"
  }, "ruby/version.h at ruby_2_6 | ruby/ruby")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/ruby/ruby/releases"
  }, "Releases · ruby/ruby | GitHub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://isotope11.com/blog/stabby-lambda"
  }, "Stabby Lambda in Ruby 1.9 – isotope|eleven")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/mruby/mruby"
  }, "mruby/mruby: Lightweight Ruby | GitHub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/@k0kubun/ruby-2-6-jit-progress-and-future-84e0a830ecbf"
  }, "Ruby 2.6 JIT - Progress and Future – k0kubun – Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bugs.ruby-lang.org/issues/12912"
  }, "Feature #12912: An endless range ", React.createElement(_components.code, null, "(1..)"), " - Ruby trunk")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bugs.ruby-lang.org/issues/14799"
  }, "Feature #14799: Startless range - Ruby trunk")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/ruby-2-6-0-released-and-changes"
  }, "루비 2.6 릴리즈 및 주요 문법 변경 사항 소개 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techlife.cookpad.com/entry/2018/12/25/110240"
  }, "(일본어) 프로가 해설하는 Ruby 2.6 NEWS 파일 | 쿡패드 개발 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/HerokuChangelog/status/1077575450732314624"
  }, "Heroku Changelog on Twitter: \"Ruby 2.6.0 is now available on Heroku")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=3G6rPeQj0-Q"
  }, "당장 네이버블로그 때려치고 유튜브 해야하는 이유(강차분PD) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.news.naver.com/read.nhn?mode=LSD&mid=sec&sid1=105&oid=030&aid=0002468872"
  }, "네이버, 파워블로그 제도 8년만에 없앤다 | 전자신문")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://adpost.naver.com/"
  }, "네이버 애드포스트 : 미디어 운영의 새로운 즐거움")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.co.kr/adsense/start/#/?modal_active=none"
  }, "웹사이트로 온라인에서 수익 창출 | Google 애드센스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.google.com/webmasters/answer/7451184?hl=en"
  }, "Search Engine Optimization (SEO) Starter Guide - Search Console Help")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.publickey1.jp/blog/18/_publickey2018.html"
  }, "(일본어)블로그로 벌어먹고 살 수 있나요 Publickey의 2018년(아마도 최종회) | Publickey")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://affiliate-program.amazon.com/"
  }, "Amazon.com Associates: The web's most popular and successful Affiliate Program")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.iamseapy.com/archives/738"
  }, "아마존 킨들 오아시스 간단 사용기 | 오늘부터 seapy는")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://partners.coupang.com/"
  }, "쿠팡 파트너스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/20229822"
  }, "쿠팡, 온라인 서점 YES24와 제휴…도서 사업 부문 강화")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/ttb/wmain.aspx"
  }, "알라딘 - Thanks To Blogger")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://blog.aladin.co.kr/ttb/10437993"
  }, "알라딘서재 - TTB2 서비스 종료 안내")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=a9Bl4KKILPo"
  }, "자동화수익 제휴마케팅 끝판왕 쿠팡파트너스 어필리에이트 첫달수익 공개 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://d.hatena.ne.jp/dangerous1192/20060731"
  }, "(일본어)만화나츠100")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.ycombinator.com/"
  }, "Hacker News")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cj.com/"
  }, "CJ Affiliate")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ebates.com/kr/"
  }, "이베이츠 – 온라인 캐시백 쇼핑, 최대 20% 캐시백을 받으세요!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://business.pinterest.com/en/blog/affiliate-links-are-now-allowed-on-pinterest"
  }, "Affiliate links are now allowed on Pinterest! | Pinterest Business")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.mk.co.kr/newsRead.php?year=2016&no=881785"
  }, "MK News - ‘못 믿을 베스트셀러’ 사재기로 순위 조작한 출판업자들")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.instapaper.com/read/1128211761"
  }, "Medium is a poor choice for blogging")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
